.card{box-shadow: 0 2px 6px 0 rgb(67 89 113 / 12%);position: relative;display: flex;flex-direction: column;min-width: 0;word-wrap: break-word;background-color: var(--bs-body-card-bg);border: 0 solid transparent;border-radius: 0.5rem;background-clip: padding-box;}
.ml-auto{margin-left: auto;}
a,a:hover {color: var(--bs-primary);text-decoration: none;}
.form-div .text-danger{font-size: 14px;}
.card .card-body{padding: 1.5rem 1.5rem;}
thead, tbody, tfoot, tr, td, th {border-color: var(--bs-border-table); transition: opacity 0.15s linear;}
.table th, .table td {padding: 0.75rem;}
.table th {color: var(--bs-body-color);font-weight: 600;}
.table tr:last-child td, .table tbody tr:last-child th{border-bottom: 0px solid;}
.table tr.cursor-pointer:hover td, .table tr.cursor-pointer:hover th{background-color: rgb(var(--bs-secondary-rgb), 0.1);}
.table .table-editor{display: none;padding: 0px;margin: auto 0px; height: 100%;vertical-align: center;}
.table tr:hover .table-editor{display: flex;top: 0px;right: 5px;align-items: center;justify-content: center;}
input[type="number"]::-webkit-outer-spin-button,input[type="number"]::-webkit-inner-spin-button {-webkit-appearance: none;margin: 0;}
input[type="number"] {-moz-appearance: textfield;}
/* Custome Toaster */
.custome_toast{position: fixed;right: 10px;bottom: 10px;border-color: transparent;}
.custome_toast .toast-header{border-bottom-color: transparent;}
.custome_toast .btn-close:hover{background-color: var(--theme-deafult);color: var(--white-color);}
.custome_toast .btn-close:focus{box-shadow: 0 0 0 0.05rem var(--theme-deafult);}
.custome_toast .btn-close{-webkit-mask-image: url("../img/close.svg");mask-image: url("../img/close.svg"); background-image: none;}

.animated {animation-duration: 1s;animation-fill-mode: both;}
@keyframes bounceIn {0% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);opacity: 0;transform: scale3d(0.3, 0.3, 0.3);}20% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);transform: scale3d(1.1, 1.1, 1.1);}40% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);transform: scale3d(0.9, 0.9, 0.9);}60% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);opacity: 1;transform: scale3d(1.03, 1.03, 1.03);}80% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);transform: scale3d(0.97, 0.97, 0.97);}to {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);opacity: 1;transform: scaleX(1);}}
.animated.bounceIn {animation-name: bounceIn;}
@keyframes bounceInDown {0% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);opacity: 0;transform: translate3d(0, -3000px, 0);}60% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);opacity: 1;transform: translate3d(0, 25px, 0);}75% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);transform: translate3d(0, -10px, 0);}90% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);transform: translate3d(0, 5px, 0);}to {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);transform: none;}}
.animated.bounceInDown {animation-name: bounceInDown;}
@keyframes bounceInLeft {0% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);opacity: 0;transform: translate3d(-3000px, 0, 0);}60% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);opacity: 1;transform: translate3d(25px, 0, 0);}75% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);transform: translate3d(-10px, 0, 0);}90% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);transform: translate3d(5px, 0, 0);}to {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);transform: none;}}
.animated.bounceInLeft {animation-name: bounceInLeft;}
@keyframes bounceInRight {0% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);opacity: 0;transform: translate3d(3000px, 0, 0);}60% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);opacity: 1;transform: translate3d(-25px, 0, 0);}75% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);transform: translate3d(10px, 0, 0);}90% {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);transform: translate3d(-5px, 0, 0);}to {animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);transform: none;}}.animated.bounceInRight {animation-name: bounceInRight;}
/* Form style */
.form-control{display: block;width: 100%;padding: 0.4375rem 0.875rem;font-weight: 400;line-height: 1.53;color: var(--bs-body-color);background-clip: padding-box;border: 1px solid var(--bs-border-color);-webkit-appearance: none;-moz-appearance: none;appearance: none;border-radius: 0.375rem;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;font-size: var(--bs-body-font-size);background-color: var(--bs-body-card-bg);}
.text-uppercase{text-transform: uppercase;}
.form-label {margin-bottom: 0.5rem;color: var(--bs-body-color);font-size: 14px;}
.cursor-pointer{cursor: pointer;}
.form-control:focus, .form-select:focus{background-color: var(--bs-body-card-bg);color: var(--bs-body-color);}
.form-control:focus,.form-select:focus, .form-check-input:focus, .input-group:focus-within .form-control, .input-group:focus-within .input-group-text{border-color: var(--bs-border-form-color);box-shadow: 0px 0px 0px red;}
.form-check-input:checked{border-color: var(--bs-border-form-color);background-color: var(--bs-primary);}
.input-group-text{ transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;display: flex;align-items: center;padding: 0.4375rem 0.875rem;font-size: 0.9375rem;font-weight: 400;line-height: 1.53;color: var(--bs-body-icon-color);text-align: center;white-space: nowrap;background-color: var(--bs-body-card-bg);border: 1px solid var(--bs-border-color);border-radius: 0.375rem;}
.form-check-input {width: 1em;height: 1em;margin-top: 0.25em;vertical-align: top;background-color: var(--white);background-repeat: no-repeat;background-position: center;background-size: contain;border: 1px solid var(--bs-border-color);appearance: none;-webkit-print-color-adjust: exact;}
.input-group-merge .input-group-text:first-child{border-right: 0;}
.input-group-merge .input-group-text:last-child{border-left: 0;}
.input-group-merge .form-control:not(:last-child){padding-right: 0;border-right: 0;}
.input-group-merge .form-control:not(:first-child) {padding-left: 0;border-left: 0;}
.container-p-y{padding-top: 1.6rem;padding-bottom: 1.6rem;}
/* login page */
.authentication-wrapper {display: flex;flex-basis: 100%;min-height: 100vh;width: 100%;}
.authentication-wrapper.authentication-basic {align-items: center;justify-content: center;overflow: hidden;}
.authentication-wrapper.authentication-basic .authentication-inner {max-width: 400px;position: relative;}
.authentication-wrapper .authentication-inner {width: 100%;}
/* Header */
.page-wrapper .page-header {max-width: 100vw;position: fixed;top: 0;z-index: 8;transition: 0.5s;background-color: var(--bs-body-card-bg);box-shadow: 0px 4px 40px rgb(39 32 120 / 10%);margin-left: 0px;width: calc(100% - 0px);}
.page-wrapper .page-header .header-wrapper {width: 100%;align-items: center;padding: 22px 20px;position: relative;}
.page-wrapper .page-header .header-wrapper .header-logo-wrapper {display: flex;align-items: center;gap: 30px;}
.page-wrapper .page-header .header-wrapper .header-logo-wrapper h5{margin-bottom: 0px;font-weight: 700;display: none;}
.page-wrapper .page-header .header-wrapper .header-logo-wrapper .logo-wrapper{padding: 0px  20px;}
.page-wrapper .page-header .header-wrapper .header-logo-wrapper .logo-wrapper img{user-select: none;}
.page-wrapper .page-header .header-wrapper .nav-right .nav-menus{display: flex;align-items: center;justify-content: flex-end;}
.page-wrapper .user-menu button{background-color: var(--theme-deafult)!important;border-color: transparent;color: var(--white-color);height: 35px;width: 35px;border-radius: 40px;display: flex;align-items: center;justify-content: center;text-transform: uppercase; }
.page-wrapper .user-menu button::after{display: none;}
.page-wrapper .page-header .menu_icon .dropdown-item{display: flex;align-items: center;}
.page-wrapper .page-header .menu_icon .dropdown-item i{height: 18px;width: 18px;margin-right: 10px;}
.page-header .toggle-sidebar button i{height: 18px;width: 18px;}
/* Side Bar */
.page-wrapper {position: relative;}
.page-body-wrapper {background-color: var(--bs-dashboard-bg);}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.mobile_menu .logo-wrapper {padding: 17px 30px 5px 30px;display: block;}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper {display: none;}
.page-wrapper .page-body-wrapper div.sidebar-wrapper {margin: 30px !important;top: 84px;bottom: 0;border-radius: 15px;position: fixed;z-index: 9;height: auto;line-height: inherit;background: var(--bs-body-card-bg);width: 265px;text-align: left;transition: 0.3s;box-shadow: 0 0 21px 0 rgb(89 102 122 / 10%)}
.page-wrapper .page-body-wrapper div.sidebar-wrapper div:first-child{
    transition: 0.3s;
}
.page-wrapper.modern-type .page-body-wrapper .page-body {overflow: overlay;margin-left: 296px;min-height: calc(100vh - 84px);margin-top: 84px;padding: 20px 15px 20px 15px;position: relative;transition: 0.5s;}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links{height: calc(100vh - 160px);left: -300px;z-index: 99;transition: color 1s ease;color: rgba(0, 0, 0, 0);margin-top: 20px;margin-bottom: 30px;position: unset;padding-left: 0px;list-style-type: none;}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-link{padding: 12px 20px;position: relative;display: block;transition: all 0.3s ease;}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-link span{letter-spacing: 0.7px;text-transform: capitalize;color: rgba(82, 82, 108, 0.8);font-weight: 700;}
.page-body-wrapper .lan-3{font-size: 16px;}
.page-wrapper.compact-wrapper .sidebar-links .sidebar-link .icon{vertical-align: -5px;float: none;margin-right: 15px;}
.page-wrapper.compact-wrapper .sidebar-links .sidebar-link.active .icon,.page-wrapper.compact-wrapper .sidebar-links .sidebar-link:hover .icon{background-color: var(--theme-deafult);}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-link.active span{color: var(--theme-deafult)!important;transition: all 0.3s ease;}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-link.active{transition: all 0.5s ease;position: relative;background-color: rgb(var(--bs-primary-rgb), 0.1); margin-bottom: 5px;}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-link.active::after{opacity: 1;visibility: visible;}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-link::after {position: absolute;content: "";width: 4px;height: 35px;right: 0;top: 50%;transform: translateY(-50%);background-color: var(--theme-deafult);border-radius: 5px 0px 0px 5px;opacity: 0;visibility: hidden;}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-link {transition: all 0.5s ease;display: block;}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list:hover > a:hover {background-color: rgba(var(--bs-primary-rgb), 0.1)!important;cursor: pointer;transition: all 0.3s ease;}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list:hover > a:hover span{transition: all 0.3s ease;color: var(--theme-deafult);}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon {display:none;width: auto;z-index: -1;background-color: transparent;box-shadow: none;}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main{display: none;}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper .back-btn {display: inline-block;float: right;font-size: 20px;}
@keyframes fadeInLeft {0% {opacity: 0;transform: translate3d(-100%, 0, 0);}to {opacity: 1;transform: none;}}
  .fadeInLeft {animation-duration: 400ms;animation-name: fadeInLeft;}
@keyframes fadeOutLeft {0% {opacity: 1;}to {opacity: 0;transform: translate3d(-100%, 0, 0);}}
  .fadeOutLeft {animation-duration: 400ms;animation-name: fadeOutLeft;}
  
  
/* Dashboard Page */
.nav-tabs .title{font-size: 16px;font-weight: 600;}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon.desktop_menu ~ .page-body{margin-left: 0px;}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.mobile_menu ~ .page-body{margin-left: 0px;}
.page-wrapper .page-body-wrapper .page-title {padding: 15px 0px;margin: 0 0px 0px;}
.page-wrapper .page-body-wrapper .page-title .col h3 {font-size: 24px;margin-bottom: 0;text-transform: capitalize;}
.page-wrapper .breadcrumb {align-items: center;justify-content: flex-end;display: flex;flex-wrap: wrap;list-style: none;margin-bottom: 0px;}
.page-wrapper .breadcrumb li {display: flex;font-size: 14px;letter-spacing: 1px;justify-content: center;align-items: center;line-height: 15px;}
.breadcrumb-item a i,.breadcrumb-item a:hover i{background-color: var(--theme-deafult)!important;}
.page-wrapper .basic_table .card{margin-bottom: 30px;}
.page-wrapper .card .card-header {background-color: var(--bs-body-card-bg);padding: 20px 30px;border-bottom: 1px solid var(--bs-border-card-header);position: relative;border-radius: 10px 10px 0 0;}
.page-wrapper .card .card-header:first-child {border-radius: 10px 10px 0 0;}
.page-wrapper .basic_table .nav-tabs .nav-item.show .nav-link,
.page-wrapper .basic_table  .nav-tabs .nav-link.active,
.page-wrapper .basic_table  .nav-tabs .nav-link:focus,
.page-wrapper .basic_table  .nav-tabs{border-color: transparent;}
.fade {transition: opacity 0.15s linear;opacity: 1;}
.page-wrapper .basic_table .nav-tabs  .btn.nav-link{display: flex;align-items: center;gap: 5px;transition: 0.3s;}
.page-wrapper .basic_table .nav-tabs  .nav-link{color: var(--bs-body-color); font-size: 16px; font-weight: bolder; text-transform: capitalize;}
.page-wrapper .basic_table .nav-tabs  .nav-link.active{color: var(--theme-deafult);}
.page-wrapper .basic_table .nav-tabs  .nav-link.active i{background-color: var(--theme-deafult);}
.page-wrapper .basic_table  .nav-tabs .nav-link:hover{background-color: rgb(var(--bs-primary-rgb),0.1);color: var(--theme-deafult); opacity: 0.9;    transition: opacity 0.15s linear;border-color: transparent;}
.page-wrapper .basic_table  .nav-tabs .nav-link:hover i{background-color: var(--theme-deafult);transition: opacity 0.15s linear;}
.nav-tabs .nav-item .nav-link{padding: 5px 20px 5px 20px;}
.page-outlet{margin-top: 10px;}
.small-widget {overflow: hidden;margin-bottom: 30px;border: none;transition: all 0.3s ease;letter-spacing: 0.5px;border-radius: 15px;box-shadow: 0px 9px 20px rgb(46 35 94 / 7%);}
.f-light {color: var(--f-light);opacity: 0.8;}
.course-widget {display: flex;gap: 8px;}
.small-widget .card_icon{height: 25px!important;width: 25px!important;background-color: white;}
.small-widget .course-widget .course-icon {position: relative;width: 54px;height: 54px;display: flex;align-items: center;clip-path: polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%);justify-content: center;}
.small-widget .course-widget .course-icon.warning{background-image: linear-gradient(297.08deg, var(--bs-warning) -4.53%, var(--bs-warning) 98.25%);}
.small-widget .course-widget .course-icon.danger{background-image: linear-gradient(297.08deg, var(--bs-danger) -4.53%, var(--bs-danger) 98.25%);}
.small-widget .course-widget .course-icon.primary{background-image: linear-gradient(297.08deg, rgb(var(--bs-primary-rgb)) -4.53%, rgb(var(--bs-primary-rgb)) 98.25%);}
.small-widget .course-widget h4{font-weight: 700;}
.bg-overlay.active {height: 100vh;width: 100vw;background-color: rgba(0, 0, 0, 0.2);position: fixed;z-index: 8;top: 0;}
/* Dashboard Count Card */
.card .media {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;padding: 30px;margin-bottom: -14px;}
.card .footer-shape{border-radius: 133% 164% 90% 98% / 82% 76% 50% 50%;padding: 23px 30px 24px 30px;width: 100%;bottom: -10px;position: relative;}
.card .bg-gradient-primary {-webkit-background-image: linear-gradient(linear, left top, right top, from(#46a5f9), to(#0878dc));background-image: linear-gradient(to right, #46a5f9, #0878dc);color: #fff;}
.card.o-hidden{overflow: hidden;}
.card .media-body {-webkit-box-flex: 1;-ms-flex: 1;flex: 1;}
.sass-widgets .media .media-body p {font-size: 12px;font-weight: 800;text-transform: uppercase;color: #6e7e96;margin-bottom: 5px;}
.media .f-w-600 {font-weight: 800;}
.sass-widgets .footer-shape .sass-footer {position: relative;margin-bottom: 7px;font-size: 15px;}
.card .mr-3,.card  .mx-3 {margin-right: 1rem !important;}
.sass-widgets .footer-shape .sass-footer span, .sass-widgets .footer-shape .sass-footer i {vertical-align: middle;font-size: 13px;}
.card .fa {display: inline-block;font: normal normal normal 14px/1 FontAwesome;font-size: inherit;text-rendering: auto;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
.card .fa-sort-up::after,
.card .fa-sort-down::after {content: "";display: inline-block;margin-left: 0.255em;vertical-align: 0.255em;content: "";border-top: 0.35em solid;border-right: 0.35em solid transparent;border-bottom: 0;border-left: 0.35em solid transparent;}
.card  .fa-sort-up::after{border-top: 0;border-bottom: 0.35em solid;}
.card .mr-4,.card .mx-4 {margin-right: 1.5rem !important;}
.sass-widgets .footer-shape .sass-footer .b-l-primary {border-left: 1px solid #5fb1fa !important;}
.card .pl-4,.card  .px-4 {padding-left: 1.5rem !important;}
.sass-widgets .footer-shape .sass-footer .small-sass {position: absolute;top: -15px;right: -18px;}
.sass-widgets .footer-shape .sass-footer .small-sass .flot-chart-container {height: 45px;width: 60px;}
.course-icon {position: relative;width: 54px;height: 54px;display: flex;align-items: center;clip-path: polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%);justify-content: center;}
.course-icon.warning {background-image: linear-gradient(297.08deg, var(--bs-warning) -4.53%, var(--bs-warning) 98.25%);}
.bg-gradient-secondary {-webkit-background-image: linear-gradient(linear, left top, right top, from(#fc6088), to(#fb1550));background-image: linear-gradient(to right, #fc6088, #fb1550);color: #fff;}
.sass-widgets .footer-shape .sass-footer .b-l-secondary {border-left: 1px solid #fc799b !important;}
.bg-gradient-info {-webkit-background-image: linear-gradient(linear, left top, right top, from(#a76cff), to(#8d41ff));background-image: linear-gradient(to right, #a76cff, #8d41ff);color: #fff;}
.sass-widgets .footer-shape .sass-footer .b-l-info {border-left: 1px solid #b27fff !important;}
/* pagination css */
.CommonPagination{padding: 10px 10px; display: flex;}
.CommonPagination .pagination{margin-left: auto;}
.pagination { padding: 10px; background-color: rgb(var(--bs-primary-rgb), 0.1);border-radius: 10px;display: inline-flex; margin-bottom: 0;justify-content: flex-end;}
.pagination .page-item .page-link{ border: 1px solid transparent;background-color: transparent;color: #898989;white-space: nowrap;display: flex;justify-content: center;align-items: center;width: 35px !important; height: 35px !important; padding: 0 !important;border-radius: 100% !important;font-size: 15px;}
.pagination .page-item.active .page-link{box-shadow: 0px 7px 20px 0 rgba(var(--bs-primary-rgb), 0.4);background-color: var(--bs-primary);color: var(--white-color);}
.pagination .page-item .page-link:focus{outline: 0;box-shadow: 0 0 0 0.1rem rgba(var(--bs-primary-rgb), 0.4);}
.pagination li:first-child{background-color: var(--white-color);border-radius: 100% !important;}
.pagination li:last-child{background-color: var(--white-color);border-radius: 100% !important;} 

/* Common Icons */
.common_icon, .custome_toast .btn-close{mask-position: center; -webkit-mask-position: center; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat; mask-size: contain; -webkit-mask-size: contain; background-color: rgb(60, 63, 74);height: 20px;width: 20px;display: inline-block;position: relative;}
.common_icon.form{height: 16px;width: 16px;}
.common_icon.eye{height: 18px;width: 18px;-webkit-mask-image: url("../img/eye.svg");mask-image: url("../img/eye.svg"); }
.common_icon.home{height: 18px;width: 18px;-webkit-mask-image: url("../img/dashboard.svg");mask-image: url("../img/dashboard.svg"); }
.common_icon.allusers{height: 18px;width: 18px;-webkit-mask-image: url("../img/allusers.svg");mask-image: url("../img/allusers.svg"); }
.common_icon.plus{height: 18px;width: 18px;-webkit-mask-image: url("../img/plus.svg");mask-image: url("../img/plus.svg"); }
.common_icon.menu{-webkit-mask-image: url("../img/Menuicon.svg");mask-image: url("../img/Menuicon.svg"); }
.common_icon.white{background-color: var(--white-color);}
.icon_button, .icon_button:active{transition: all 0.3s ease;height: 40px;width: 40px;padding: 0px;display: flex;align-items: center;justify-content: center;border-color: transparent!important;outline: 0px;}
.icon_button:hover, .icon_button.active{height: 40px;width: 40px;transform: scale(1);background-color: rgba(var(--bs-primary-rgb), 0.1)!important;}
.icon_button:hover i{background-color: var(--theme-deafult)!important;}
.icon_button .pr-color{background-color: var(--bs-primary)!important;}
.icon_button.rounded{border-radius: 40px!important;}
.common_icon.email{-webkit-mask-image: url("../img/mail.svg");mask-image: url("../img/mail.svg");height: 20px;width: 20px;}
.common_icon.lock{-webkit-mask-image: url("../img/lock.svg");mask-image: url("../img/lock.svg");height: 20px;width: 20px;}
.common_icon.dashboard{-webkit-mask-image: url("../img/dashboard.svg");mask-image: url("../img/dashboard.svg");height: 20px;width: 20px;}
.common_icon.profile{-webkit-mask-image: url("../img/profile.svg");mask-image: url("../img/profile.svg");height: 20px;width: 20px;}
.common_icon.userlist{-webkit-mask-image: url("../img/userlist.svg");mask-image: url("../img/userlist.svg");height: 20px;width: 20px;}
.common_icon.questions{-webkit-mask-image: url("../img/question.svg");mask-image: url("../img/question.svg");height: 20px;width: 20px;}
.common_icon.mode{-webkit-mask-image: url("../img/mode.svg");mask-image: url("../img/mode.svg");height: 20px;width: 20px;}
.common_icon.edit{-webkit-mask-image: url("../img/edit.svg");mask-image: url("../img/edit.svg");height: 20px;width: 20px;}
.common_icon.eye-slash{-webkit-mask-image: url("../img/eye-slash.svg");mask-image: url("../img/eye-slash.svg");height: 20px;width: 20px;}
.common_icon.trash{-webkit-mask-image: url("../img/trash.svg");mask-image: url("../img/trash.svg");height: 20px;width: 20px;}
.common_icon.pencil{-webkit-mask-image: url("../img/pencil.svg");mask-image: url("../img/pencil.svg");height: 20px;width: 20px;}
.common_icon.system{-webkit-mask-image: url("../img/system.svg");mask-image: url("../img/system.svg");height: 20px;width: 20px;}
.common_icon.check{-webkit-mask-image: url("../img/check.svg");mask-image: url("../img/check.svg");height: 20px;width: 20px;}
.common_icon.systemcard{-webkit-mask-image: url("../img/systemcard.svg");mask-image: url("../img/systemcard.svg");height: 20px;width: 20px;}
.common_icon.logout{-webkit-mask-image: url("../img/logout.svg");mask-image: url("../img/logout.svg");height: 20px;width: 20px;}
.common_icon.usermenu{-webkit-mask-image: url("../img/usermenu.svg");mask-image: url("../img/usermenu.svg");height: 20px;width: 20px;}
.common_icon.chevron-left{-webkit-mask-image: url("../img/chevron-left.svg");mask-image: url("../img/chevron-left.svg");height: 20px;width: 20px;}
.common_icon.activeusers{-webkit-mask-image: url("../img/activeusers.svg");mask-image: url("../img/activeusers.svg");height: 20px;width: 20px;}
.common_icon.fav{-webkit-mask-image: url("../img/fav.svg");mask-image: url("../img/fav.svg");height: 20px;width: 20px;}
.common_icon.stat{-webkit-mask-image: url("../img/stat.svg");mask-image: url("../img/stat.svg");height: 30px;width: 30px;}
.common_icon.qsystem{-webkit-mask-image: url("../img/qsystem.svg");mask-image: url("../img/qsystem.svg");height: 20px;width: 20px;}
.common_icon.pr-color{background-color: var(--theme-deafult);}
/* Icon Buttons */
.icon_btn{padding: 2px;height: 30px;width: 30px;display: inline-flex;align-items: center;justify-content: center;border-radius: 5px;margin: 0px 5px;border-color: transparent!important;}
.icon_btn i{height: 15px!important;width: 15px!important;background-color: var(--bs-secondary);}
.icon_btn:hover i{background-color: var(--theme-deafult)!important;}
.icon_btn:hover i.trash{background-color: var(--bs-danger)!important;}
/* Confirm Modal */
.swal-modal {text-align: center;z-index: 10001;}
.swal-overlay--show-modal .swal-modal {opacity: 1;pointer-events: auto;box-sizing: border-box;}
.swal-icon:first-child {margin-top: 32px;}
.swal-icon {width: 85px;height: 85px;border-width: 4px;border-style: solid;border-radius: 50%;padding: 0;position: relative;box-sizing: content-box;margin: 20px auto;display: flex;align-items: center;justify-content: center;}
.swal-icon--warning__body, .swal-icon--warning__dot {position: absolute;left: 50%;background-color: #f8bb86;}
.swal-icon--warning__body {width: 5px;height: 47px;top: 10px;border-radius: 2px;margin-left: -2px;}
.swal-icon--warning__dot {width: 8px;height: 8px;border-radius: 50%;margin-left: -4px;bottom: -14px;}
.modal-title {color: rgba(0,0,0,.65);font-weight: 700;text-transform: none;position: relative;display: block;padding: 13px 16px;font-size: 27px;line-height: normal;text-align: center;margin-bottom: 0;margin-bottom: 13px;}
@keyframes pulseWarning{0%{border-color:#f8d486}to{border-color:#f8bb86}}
@-webkit-keyframes pulseWarning{0%{border-color:#f8d486}to{border-color:#f8bb86}}
.swal-icon--warning {border-color: #f8bb86;-webkit-animation: pulseWarning .75s infinite alternate;animation: pulseWarning .75s infinite alternate;}
.swal-text {font-size: 16px;position: relative;float: none;line-height: normal;vertical-align: top;text-align: left;display: inline-block;margin: 0;padding: 0 10px;font-weight: 400;color: var(--bs-body-color);max-width: calc(100% - 20px);overflow-wrap: break-word;box-sizing: border-box;}
.swal-footer {text-align: center;padding: 13px 16px;border-radius: inherit;border-top-left-radius: 0;border-top-right-radius: 0;border-color: transparent;}
.swal-button__loader {position: absolute;height: auto;width: 43px;z-index: 2;left: 50%;top: 50%;transform: translateX(-50%) translateY(-50%);text-align: center;pointer-events: none;opacity: 1;display: flex;align-items: center;justify-content: center;}
.swal-button__loader div {display: inline-block;float: none;vertical-align: baseline;width: 9px;height: 9px;padding: 0;border: none;margin: 2px;opacity: .4;border-radius: 7px;background-color: hsla(0,0%,100%,.9);transition: background .2s;-webkit-animation: swal-loading-anim 1s infinite;animation: swal-loading-anim 1s infinite;}
@-webkit-keyframes swal-loading-anim{0%{opacity:.4}20%{opacity:.4}50%{opacity:1}to{opacity:.4}}
@keyframes swal-loading-anim{0%{opacity:.4}20%{opacity:.4}50%{opacity:1}to{opacity:.4}}

.swal-button {background-color: var(--theme-deafult);color: var(--white-color);border: none;box-shadow: none;font-weight: 600;padding: 0.45rem 1.75rem;margin: 0;cursor: pointer;border-radius: 0.25rem;}
.swal-button--cancel {background-color: var(--bs-info-color) !important;color: var(--bs-body-color);}
.swal-button-container {margin: 5px;display: inline-block;position: relative;}

/* Add Mode */
.box-mode{transition: all 0.7s ease; box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0);position: relative;display: flex;flex-direction: column;min-width: 0;word-wrap: break-word;background-color: transparent;border: 0 solid transparent;border-radius: 0.5rem;background-clip: padding-box;margin: 0pc auto;height: 230px;width: 380px;}
.box-mode .card-body{display: flex;align-items: center;justify-content: center;flex-direction: column;}
.box-mode .card-body input{border-color: transparent;background-color: transparent;}
.colorSelection{border-color: transparent!important;display: inline-flex;align-items: center;justify-content: center;height: 40px;width: 40px;padding: 2px;margin: 0px 14px; border-radius: 30px;transition: all 0.3s ease; }
.colorSelection i {animation-name: zoomIn ;animation-duration: 500ms;}
@keyframes zoomIn {0% {opacity: 0;transform: scale3d(0.3, 0.3, 0.3);}50% {opacity: 1;}}