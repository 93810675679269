@import url('https://fonts.googleapis.com/css2?family=Noto+Emoji:wght@500&family=Sofia+Sans:wght@300;400;500;600;700;800;900&display=swap');
.body {
  --theme-secondary: #f73164;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-info-color:#EFEFEE;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 105, 108, 255;
  --bs-secondary-rgb: 108,117,125;
  --bs-success-rgb: 25,135,84;
  --bs-info-rgb: 13,202,240;
  --bs-warning-rgb: 255,193,7;
  --bs-danger-rgb: 220,53,69;
  --bs-light-rgb: 248,249,250;
  --bs-dark-rgb: 33,37,41;
  --bs-white-rgb: 255,255,255;
  --bs-black-rgb: 0,0,0;
  --bs-body-color-rgb: 33,37,41;
  --bs-body-bg-rgb: 255,255,255;
  --bs-blue: var(--bs-primary);
  --theme-deafult: rgb(var(--bs-primary-rgb));
  --bs-primary: rgb(var(--bs-primary-rgb));
  --bs-border-form-color: rgb(var(--bs-primary-rgb));
  --bs-font-sans-serif: 'Sofia Sans', sans-serif;
  --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 15px;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-icon-color:#5c5c85;
  --bs-body-bg: #f5f5f9;
  --bs-body-card-bg: #fff;
  --white-color:#fff;
  --white:#fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: var(--bs-primary);
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-color:#bfbfbf;
  --bs-border-card-header:#ecf3fa;
  --bs-border-table:#dee2e6;
  --bs-button-color:white;
  --bs-dashboard-bg:#F7F8F9;
  --f-light:#52526C;
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  letter-spacing: 0.8px;
}
.body .btn{--bs-btn-font-size:14px;font-size: var(--bs-btn-font-size);color: var(--bs-button-color);}
.body .btn-primary{
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-color: var(--bs-button-colorbg);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: var(--bs-body-bg);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-body-bg);
  --bs-btn-disabled-bg: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
}

.scrolloverlay{overflow: overlay;}
.body::-webkit-scrollbar,
.scrolloverlay::-webkit-scrollbar{
  width: 2px;
}
.body::-webkit-scrollbar-track,
.scrolloverlay::-webkit-scrollbar-track{
  background-color: transparent;
}
.body::-webkit-scrollbar-thumb,
.scrolloverlay::-webkit-scrollbar-thumb{
  border-radius: 20px;
  background-color: rgb(var(--bs-primary-rgb), 0.5);
}
.body.dark {
  --bs-dashboard-bg: #1e2227;
  --bs-body-bg: #1e2227;
  --bs-body-color: #f2f2f2;
  --bs-border-form-color:#5558ff;
  --bs-body-card-bg: #31383e ;
  --white-color:#31383e;
  --bs-body-icon-color:#b8b8d5;
  --bs-button-color:white;
  --bs-border-color:#61676b;
}
.body .table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 0rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.body .dropdown-item{
  --bs-dropdown-link-active-bg: var(--white-color);
  --bs-dropdown-link-active-color:var(--bs-body-color)
}
.body .dropdown-menu{
  border-color: transparent;
  box-shadow: 0px 4px 40px rgb(39 32 120 / 10%);
}